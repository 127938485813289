import { Component, OnInit } from '@angular/core';
// import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
import Swal from 'sweetalert2'
import { ActivatedRoute } from '@angular/router';
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-manage-webrtc',
  templateUrl: './manage-webrtc.component.html',
  styleUrls: ['./manage-webrtc.component.css']
})
export class ManageWebrtcComponent implements OnInit {

  constructor(private serverService: ServerService,private route: ActivatedRoute,private router:Router) { }
  server_list;
  ngOnInit() {
    var userId = localStorage.getItem('userId');
    if(userId == '1'){
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You have no access view that page!',
      });
      this.router.navigate(['/']);
      return false;
    }
    this.listwebrtc();
  }
  listwebrtc(){
    var access_token=localStorage.getItem('access_token');
    let api:any='{"operation":"call", "moduleType":"call", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"list_webrtc_servers"}}';
    this.serverService.sendServer(api).subscribe((response:any)=>{
      if(response.status ==true){
        // alert('cksnns')
        this.server_list=response.result.data;
        console.log(this.server_list);
      }
    })
    
  }
  addserver(){
    $('#addserver_form').modal('show');
  }
  addNewWebrtc(){
    // var access_token=localStorage.getItem('access_token');
    let api_req:any = new Object();
    let queue_req:any = new Object();
 if( $('#server_name').val() != '' && $('#server_fqdn').val() !='' && $('#server_ip').val()!='' && $('#server_location').val()!=''){

 }
else{
  iziToast.warning({
    message:"Please fill all the details",
    position: "topRight"
  })
  return false;

}

    api_req.operation="call";
    api_req.moduleType="call";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');

    queue_req.action="create_servers";
    queue_req.server_name=$('#server_name').val();
    queue_req.server_fqdn=$('#server_fqdn').val();
    queue_req.server_ip=$('#server_ip').val();
    queue_req.server_location=$('#server_location').val();
    api_req.element_data = queue_req;
          this.serverService.sendServer(api_req).subscribe((response:any) => {
            if(response.status=="true"){
           
       alert('sasa')
              
            }
              

          }, 
          (error)=>{
              console.log(error);
          });

  }
}
